<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      class="a"
      width="24"
      height="24"
    />

    <path
      class="b"
      d="M61-981a8.009,8.009,0,0,1,8-8,8.01,8.01,0,0,1,8,8,8.01,8.01,0,0,1-8,8,8.01,8.01,0,0,1-8-8Zm1.143,0A6.866,6.866,0,0,0,69-974.14,6.866,6.866,0,0,0,75.857-981,6.866,6.866,0,0,0,69-987.857,6.866,6.866,0,0,0,62.143-981Zm.571,0a6.249,6.249,0,0,1,.823-3.107.568.568,0,0,1,.043-.073A6.289,6.289,0,0,1,69-987.286a6.29,6.29,0,0,1,5.41,3.089.573.573,0,0,1,.065.111,6.242,6.242,0,0,1,.8,2.7q0,.021,0,.043.009.169.009.342A6.294,6.294,0,0,1,69-974.712,6.294,6.294,0,0,1,62.714-981ZM69-975.855a5.187,5.187,0,0,0,.6-.035,6.808,6.808,0,0,1,4.492-5.842,5.1,5.1,0,0,0-.485-1.554H64.394a5.1,5.1,0,0,0-.484,1.553,6.806,6.806,0,0,1,4.5,5.844A5.151,5.151,0,0,0,69-975.855Zm-1.779-.317a5.664,5.664,0,0,0-3.341-4.344,5.158,5.158,0,0,0,3.341,4.344Zm3.563,0a5.157,5.157,0,0,0,3.336-4.341,5.666,5.666,0,0,0-3.336,4.339Zm2.046-8.255A5.131,5.131,0,0,0,69-986.143a5.131,5.131,0,0,0-3.83,1.714ZM67.287-981A1.717,1.717,0,0,1,69-982.715,1.717,1.717,0,0,1,70.716-981,1.716,1.716,0,0,1,69-979.286,1.716,1.716,0,0,1,67.287-981Zm1.143,0a.572.572,0,0,0,.572.571.572.572,0,0,0,.571-.571.572.572,0,0,0-.571-.572A.572.572,0,0,0,68.43-981Z"
      transform="translate(-57 992.996)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Drive',
};
</script>

<style lang="scss" scoped>
  .a {
    fill: none;
  }

  .b{
    fill: currentColor;
  }
</style>
